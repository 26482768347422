import React from 'react'
import PropTypes from 'prop-types'
import Button from 'reactstrap/lib/Button'
import notFoundGif from './resources/en-us/images/notfound.gif'
import './NotFound.scss'

function NotFound({ onGoHome, strings }) {
  return (
    <main role="main" className="not-found-main">
      <div className="not-found-container text-center row">
        <h1 data-testid="not-found-header" className="display-2 col-12">
          {strings.alertTitle}
        </h1>
        <div className="not-found-img col-12" style={{ backgroundImage: `url(${notFoundGif})` }} />
        <h3 className="mb-2 col-12">{strings.description}</h3>
        <div className="col-12">
          <Button onClick={() => onGoHome()} className="btn-lg">
            {strings.goHomeActionText}
          </Button>
        </div>
      </div>
    </main>
  )
}

NotFound.propTypes = {
  onGoHome: PropTypes.func,
  strings: PropTypes.shape({
    alertTitle: PropTypes.string,
    description: PropTypes.string,
    goHomeActionText: PropTypes.string
  }).isRequired
}

NotFound.defaultProps = {
  onGoHome: () => {
    window.location.href = '/'
  }
}

export default NotFound
